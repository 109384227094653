nameVal = document.getElementById('first-name');
emailVal = document.getElementById('email');
budgetVal = document.getElementById('budget');
contactVal = document.getElementById('contact');
messageVal = document.getElementById('message');
attachVal = document.getElementById('attachment');
confirmPopup = document.getElementById('confirm_popup');
confirmPopup.style.display="none";
// console.log(attachVal.value,'val')
// console.log(messageVal,'val')






async function FormSubmit(event) {
    event.preventDefault()
    let submitBtn = document.getElementById('form-button')
    submitBtn.setAttribute("editable", true);
   
    const Formdata = {
        name: nameVal.value,
        email: emailVal.value,
        purpose: budgetVal.value,
        phone: contactVal.value,
        description: messageVal.value,
    }

    const response = await fetch('https://backend.quokkalabs.com/api/contact-form', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "IdentityToken": 'CWbY2nofDJFIT98WtgZnTpx6OIbnDH9sxuiabUHlcfs='
        },
        body: JSON.stringify(Formdata)
    })
        .then((response) => {
            console.log(response)
            if (response.status === 200) {
                
                confirmPopup.style.display = 'block'
                nameVal.value = "";
                emailVal.value = "";
                budgetVal.value = "";
                contactVal.value = "";
                messageVal.value = "";

                document.getElementById('form-button').addEventListener('click', function(event) {
                  // Prevent the browser's default form submission action.
                  console.log('event',event)
                  // event.preventDefault();
                
                  ga('send', 'event', {
                    eventCategory: 'Contact',
                    eventAction: 'Information Request',
                    eventLabel: 'Contact Form',
                    hitCallback: function() {
                      document.querySelector('form-button').trigger('submit');
                    }
                  });
                });
                
            }
        


            submitBtn.setAttribute("editable", false);
        })
}

function closeConfirmPopup(){
    confirmPopup.style.display = 'none'
}


// validation form parameter
// function submitGotProjectForm(evt) {
//     evt.preventDefault();
//     emailIdValidation(evt);
//     console.log("data......")
//   }

//   function phoneNumberValidate(evt){
//     let mobileNo=document.getElementById("contact").value;
//     let mobileNoExp=/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,9}$/im;
//     let form = evt.target;
//     if(mobileNo.match(mobileNoExp)){
//       // submitGotProject(form);
//       // return true;
//     }else{
//     alert("Please Fill a Valid Number")
//     return false;
//     }
//   }

//   function emailIdValidation(evt){
//   let userEmail = document.getElementById("email").value;
//   let userEmailExp= /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

//   if(userEmail.match(userEmailExp)){
//     phoneNumberValidate(evt);
//     return true;
//   }else{
//    alert("Please Fill Valid Email Id")
//     return false;
//   }
//   


document.getElementById('form-button').addEventListener('click', function(event) {
  // Prevent the browser's default form submission action.
  console.log('event',event)
  // event.preventDefault();

  ga('send', 'event', {
    eventCategory: 'Contact',
    eventAction: 'Information Request',
    eventLabel: 'Contact Form',
    hitCallback: function() {
      document.querySelector('form-button').trigger('submit');
    }
  });
});




